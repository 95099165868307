var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"submitPrevent"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"User ID"}},[_c('validation-provider',{attrs:{"name":"User ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"User ID"},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Order Status"}},[_c('validation-provider',{attrs:{"name":"Order Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":[
            {name:'Pending',id:0},
            {name:'Success',id:1},
            {name:'Failed',id:2} ],"label":"name"},model:{value:(_vm.order_status),callback:function ($$v) {_vm.order_status=$$v},expression:"order_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Payment ID"}},[_c('validation-provider',{attrs:{"name":"Payment ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Payment ID"},model:{value:(_vm.payment_id),callback:function ($$v) {_vm.payment_id=$$v},expression:"payment_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Product")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.product_list),function(product,index){return _c('tr',{key:index},[_c('td',[_c('v-select',{attrs:{"options":_vm.productionOptions,"label":"name_en"},model:{value:(_vm.item.product),callback:function ($$v) {_vm.$set(_vm.item, "product", $$v)},expression:"item.product"}})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Quantity"},model:{value:(_vm.item.quantity),callback:function ($$v) {_vm.$set(_vm.item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('td')])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
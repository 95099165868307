<template>
  <section id="dashboard-ecommerce" class="font-14">
      <div class="sec1 d-flex justify-content-between pb-1">
        <div class="d-flex">
          <div @click="handleBack" class="mr-1 d-flex align-items-center mb-25">
            <feather-icon size="24" icon="ArrowLeftIcon" class="text-body" />
          </div>
        <h3 class="text-black">Update Homepage Status</h3>
        </div>
        <div>
        </div>
      </div>
      <div>
        <b-card>
          <button class="btn btn-primary btn-sm m-1 float-right" @click="submitData">Update</button>
    <table class="table">
      <thead>
        <tr>
          <th>Likecard ID</th>
          <th>Category</th>
          <th>Home Page Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="3">
            <div class="d-flex justify-content-center align-items-center">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </div>
          </td>
        </tr>
        <tr  v-else-if="data.length == 0">
          <td colspan="3">
          <div class="d-flex justify-content-center align-items-center">
            <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
          </div>
        </td>
        </tr>
        <tr v-else v-for="item in data" :key="item.id">
          <td>{{ item.likecard_category_id }}</td>
          <td>{{ item.name_en }}</td>
          <td>
                  <b-form-checkbox v-model="item.home_page_status" :checked="item.home_page_status" class="my-checkbox ml-1" 
                    name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
            </td>
        </tr>
      </tbody>
    </table>
  </b-card>
      </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      data:[],
      isLoading:true
    }
  }, mounted() {
    this.getData()
  },
  computed:{
    },
  created() {},
  methods: {
    submitData(){
      this.isLoading = true
      let data = this.data
      data.map(i => {
        i.home_page_status = i.home_page_status ? 1 : 0
      })
        this.$http.post(`${this.baseURL}/category-likecard/update-home-page-status`, {data:data},
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 'error') {
              this.isLoading = false
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }else {
            this.isLoading = false
              this.data = []
            json.data.data.map(i => {
              let data = {
                home_page_status : i.home_page_status ? true : false,
                name_en : i.name_en,
                likecard_category_id: i.likecard_category_id,
                id: i.id
              }
              this.data.push(data)
            })
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
    },
    getData(){
      this.$http.post(`${this.baseURL}/category-likecard/get-home-page-status`).then((res) => {
            console.log(res.data.data,"res-=-=")
            this.data = []
            res.data.data.map(i => {
              let data = {
                home_page_status : i.home_page_status ? true : false,
                name_en : i.name_en,
                likecard_category_id: i.likecard_category_id,
                id: i.id
              }
              this.data.push(data)
            })
            this.isLoading = false
          })
    },
    handleBack(){
      this.$emit("handleClickBack");
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select {
  max-width: none;
}
</style>

<template>
  <section id="dashboard-ecommerce" class="font-14">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h3 class="text-black">Orders{{ search}}{{ app }}</h3>
        <div>
      <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
          <div class="center cus`tom-vs-select" style="border: none;width:5cm">
            <v-select :searchable="false" v-model="statusFilter" class="" :clearable="false" shadow
              placeholder="Select" :options="['All', 'Active', 'Inactive']">
              <!-- <v-option class="d-none"
                >Status</v-option -->
              <!-- > -->
              <!-- <v-option label="All" value="all">All</v-option>
              <v-option label="Active" value="active">Active</v-option>
              <v-option label="Inactive" value="inactive">Inactive</v-option> -->
            </v-select>
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary title="Export CSV"
          variant="outline-primary" class="btn notofication-btn mr-1" @click="exportToCSV">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>

        <button id="popover-button-variant" :style="popoverShow ? 'background-color: black;' : ''" href="#" tabindex="0"
        type="button" class="btn notofication-btn mr-1" @click="onPopovcerOpen()">
          <div>
            <img v-if="!popoverShow" id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />

            <img v-else id="task2" src="../../../assets/images/client/task-light.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-variant" triggers="click" class="custom-popover"
          :show.sync="popoverShow">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>
          <button @click="HandelClickMultipleEnableDisable()" v-b-tooltip.hover.v-primary title="Active-Inactive"  type="button" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
        </b-popover>

        <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 16px"
           >
           <!-- v-b-modal.addOrder -->
          +&nbsp; Add Order
        </button>
      </div></div>
      <b-modal id="showmodal" ref="showmodal" size="lg" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Order </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="4">
              <div class="d-flex">
                <div>
                <div class="mb-half">Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.user_name ? showItem.user_name : '(not set)'  }}
                  </div>
                </div>
              </div>
            </b-col>
           
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Order</div>
                  <div class="font-weight-bolder text-black">{{showItem.order}}</div>
                </div>
              </b-col>
              
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Email</div>
                  <div class="font-weight-bolder text-black">{{showItem.email}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Phone Number</div>
                  <div class="font-weight-bolder text-black">{{showItem.phone_number}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">No. of Product</div>
                  <div class="font-weight-bolder text-black">{{parseInt(showItem.product ? showItem.product.item_qty : '')}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Product</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.product_name : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Order Total</div>
                  <div class="font-weight-bolder text-black">{{showItem.order_total}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Likecard Order Total</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.likecard_order_price : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Vat</div>
                  <div class="font-weight-bolder text-black">{{showItem.vat}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Vat Invoice</div>
                  <div class="font-weight-bolder text-black">{{showItem.invoice_number}}</div>
                </div>
              </b-col>
              <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Order Status</div>
                  <div class="font-weight-bolder">
                    <div v-if="showItem.order_status" class="status-btn">
                      <strong><span class="boder">Active</span></strong>
                    </div>
                    <div v-else class="danger-btn">
                      <strong><span class="boder">Inactive</span></strong>
                    </div>
                  </div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Date</div>
                  <div class="font-weight-bolder text-black">{{showItem.invoice_date}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Serial No</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.likecard_serial_code : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Transaction ID</div>
                  <div class="font-weight-bolder text-black">{{showItem.payment ?showItem.payment.id: ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">ARN</div>
                  <div class="font-weight-bolder text-black">{{showItem.payment ?showItem.payment.acquirer_reference_number: ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Payment Method</div>
                  <div class="font-weight-bolder text-black">{{showItem.payment? showItem.payment.payment_mode : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Coupon Code</div>
                  <div class="font-weight-bolder text-black">{{showItem.coupon_code}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Serial Number</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.likecard_serial_number : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Serial ID</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.likecard_serial_id : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Likecard Order ID</div>
                  <div class="font-weight-bolder text-black">{{showItem.product ? showItem.product.likecard_orderId : ''}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Mail Sent</div>
                  <div class="font-weight-bolder text-black">{{showItem.mail_sent ? 'Sent' : 'Not Sent'}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">ICMS Order</div>
                  <div class="font-weight-bolder text-black">{{showItem.order_status ? 'Yes' : 'No'}}</div>
                </div>
              </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Date/Time</div>
                <div class="font-weight-bolder  text-black">{{ showItem.created_at }}</div>
              </div>
            </b-col>
            </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="$refs.showmodal.hide()">
              Close
            </button>
            </div>
      </b-modal>
      
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="productData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <draggable
          v-model="productData"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline"
        >
          <b-card
          @dblclick="handelShow(item)"
            no-body
            class="card-revenue-budget dash-total-card mb-2 drag-el"
            v-for="(item, index) in productData"
            :key="index"
          >
            <b-row class="p-1">
              
              <b-col md="3" class="d-flex align-items-center">
                  <div class="mr-1">
                    <b-form-checkbox v-if="popoverShow" @change="checkboxClicked" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl"
                    inline />
                  </div>
                  <div>
                    <div class="mb-half">Email</div>
                    <div class="font-weight-bolder text-black">{{item.email.length > 13 ? item.email.slice(0,13)+'...': item.email}}</div>
                  </div>
              </b-col>
              
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Net Total</div>
                  <div class="font-weight-bolder text-black">{{parseFloat(item.net_total)}}</div>
                </div>
              </b-col>
              
              <b-col md="1" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Order</div>
                  <div class="font-weight-bolder text-black">{{item.order}}</div>
                </div>
              </b-col>

              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Order Total</div>
                  <div class="font-weight-bolder text-black">{{parseFloat(item.order_total)}}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Order Status</div>
                  <div class="font-weight-bolder">
                    <div v-if="item.order_status" class="status-btn">
                      <strong><span class="boder">Active</span></strong>
                    </div>
                    <div v-else class="danger-btn">
                      <strong><span class="boder">Inactive</span></strong>
                    </div>
                  </div>
                </div>
              </b-col>
               <b-col md="2" class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;"
                v-b-modal="'myModal'" 
            @click="handelShow(item)"> View</button>
            </b-col>
            </b-row>
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !productData.length }"></div>
      </div>

      <button v-if="!isLoading && productData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control paginationInput" :style="'width:' + ((pageNo.toString().length*10)) + 'px'" @input="pagechange" type="number"/>
        <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
        
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>

    <!-- <b-modal id="addOrder" ref="addOrder" size="lg" class="m-2 custom--model">
      <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Add Order </h2>
              </div>
            </div>
            <b-button @click="$refs.addOrder.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
      <add-order/>
    </b-modal> -->
    <!-- </div> -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import vSelect from 'vue-select';
import moment from 'moment';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import AddOrder from './AddOrder.vue';
export default {
  components: {
    draggable,
    vSelect,
    AddOrder
  },
  data() {
    return {
      productDetailsShow: false,
      selectedValue: "",
      selectAllLabel: 'Select All',
      data: {},
      paginationStart:0,
      paginationEnd:5,
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      productData: [],
      productData1: [],
      popoverShow: false,
      paginationLength: 0,
      popoverShow1: false,
      editItemId:'',
      pageNo:1,
      isLoading:false,
      showItem:{},
      value: "1",
      statusFilter:'All',
      checkBoxIds:{}
    };
  },
  mounted() {
    this.getProduct()
    this.$eventBus.$on("handleClickCreate", () => {
      this.productDetailsShow = !this.productDetailsShow;
      this.getProduct();
    }); 
  },
  computed:{
      search() {
          // let data = []
          let search = this.$store.state.searchData
          // data = this.productData1.filter(item => ((item.status == 1 ? 'Active' : 'Inactive').toLowerCase().includes(search) 
          //                                              || item.title.replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
          // this.productData = data
          this.getSearch(search);
        }, 
        app() {
          this.$store.state.searchData = ''
          this.getProduct()
        }
    },
  created() {},
  methods: {
    HandelClickMultipleEnableDisable(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading =true
        this.$http.post(`${this.baseURL}/order-likecard/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.checkBoxIds = {}
            this.popoverShow= false
            this.getClientStore()
            this.selectAllLabel = 'Select All'
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.productData.map(item => {
        let data = {
          Product: item.product.product_name,
          Email: item.email,
          'Net Total': item.net_total,
          Order: item.order,
          'Order Status': item.order_status ? 'Active' :"Inactive",
          'Net Total': item.net_total,
          'Net Total': item.net_total,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow3={}
      this.popoverShow = false
      this.showItem = item
    },
    checkboxClicked(){
      this.$forceUpdate();
    },
    selectAllClick() {
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.productData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/order-likecard/search`, { search: search, pageno: this.pageNo},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.productData = res.data.data
          this.paginationLength = res.data.totaldata
          this.productData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    HandleClickEdit(item){
    this.editItemId = item.id
    this.productDetailsShow = !this.productDetailsShow;
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/order-likecard/search`, { search: search, pageno: this.pageNo}).then((res) => {
        this.productData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.paginationLength = res.data.totaldata
        this.productData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/order-likecard/index`, { pageno: this.pageNo}).then((res) => {
        this.productData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.paginationLength = res.data.totaldata
        this.productData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
    },
    FilterStatus(status){
      let data
      if(status == 'All') data = this.productData1
      else if(status == 'Active') data = this.productData1.filter(item => (item.status))
      else if(status == 'Inactive') data = this.productData1.filter(item => (!item.status))
      this.productData = data
    },
    async getProduct(){
      if (this.$store.state.application) {
      this.isLoading = true
        this.$http.post(`${this.baseURL}/order-likecard/index`, { pageno: 1}).then((res) => {
            this.productData=res.data.data
            this.paginationLength = res.data.totaldata
            this.productData1=res.data.data
            this.isLoading = false
          })
        }
        },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleClickCreate() {
      this.editItemId = null
      this.productDetailsShow = !this.productDetailsShow;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.custom-radio {
  border: none;
  border-radius: 10px;
  color: #000 !important;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  min-width: 250px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}

.modal-body {
  padding: 0px 40px 40px 40px !important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  padding:0;
}

textarea.form-control {
  min-height: 150px !important;
}

.popover1-body {
  padding: 0;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>

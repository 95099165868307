<template>
  <section id="dashboard-ecommerce" class="font-14">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h3 class="text-black">Products{{ search}}{{ app }}</h3>
      </div>
      <b-modal id="showmodal" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Products </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="12">
              <div class="d-flex align-items-center">
                <div>
                <div class="mb-half">Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.name_en ? showItem.name_en : '(not set)'  }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Likecard Product ID</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.likecard_product_id }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Category</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.likecard_category_id ? showItem.likecard_category_id.name_en :''}}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Status</div>
                  <div v-if="showItem.available" class="status-btn">
                      <strong><span class="boder">Available</span></strong>
                    </div>
                    <div v-else class="danger-btn">
                      <strong><span class="boder">Unavailable</span></strong>
                    </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Product Price</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.product_price }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Product Price without Vat </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.product_price_without_vat }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Sell Price</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.sell_price }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Sell Price without Vat </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.sell_price_without_vat }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Currency </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.currency_en }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Available </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.available }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Vat Amount </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.vat_amount }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Vat Percentage </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.vat_percentage }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Image Url </div>
                <b-img
                    v-if="checkURL(showItem.image)"
                    v-bind="mainProps"
                    :src="showItem.image" 
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <b-img
                    v-else
                    v-bind="mainProps"
                    :src="require('@/assets/images/no-pic.png')"
                    alt="Circle image"
                    class="d-inline-block"
                  />
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Image White Label</div>
                <b-img
                    v-if="checkURL(showItem.image_white_label)"
                    v-bind="mainProps"
                    :src="showItem.image_white_label" 
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <b-img
                    v-else
                    v-bind="mainProps"
                    :src="require('@/assets/images/no-pic.png')"
                    alt="Circle image"
                    class="d-inline-block"
                  />
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Date/Time</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.created_at }}</div>
              </div>
            </b-col>
            </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="$refs.showmodal.hide()">
              Close
            </button>
            </div>
          </b-modal>
      
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="productData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <draggable
          v-model="productData"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline"
        >
          <b-card
            no-body
            @dblclick="handelShow(item)"
            class="card-revenue-budget dash-total-card mb-2 drag-el"
            v-for="(item, index) in productData"
            :key="index"
          >
            <b-row class="p-1">
              <b-col md="2" class="d-flex align-items-center pl-2">
                  <div>
                    <div class="mb-half">Name</div>
                    <div class="font-weight-bolder text-black">
                      {{ item.name_en }}
                    </div>
                  </div>
              </b-col>
              <!-- <b-col md="1" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Likecard Product ID</div>
                  <div class="font-weight-bolder text-black">{{item.likecard_product_id}}</div>
                </div>
              </b-col> -->
              
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Category</div>
                  <div class="font-weight-bolder text-black">{{item.likecard_category_id ? item.likecard_category_id.name_en : ''}}</div>
                </div>
              </b-col>
              
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Product Price</div>
                  <div class="font-weight-bolder text-black">{{item.product_price}}</div>
                </div>
              </b-col>

              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Sell Price</div>
                  <div class="font-weight-bolder text-black">{{item.sell_price}}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Status</div>
                  <div class="font-weight-bolder">
                    <div v-if="item.available" class="status-btn">
                      <strong><span class="boder">Available</span></strong>
                    </div>
                    <div v-else class="danger-btn">
                      <strong><span class="boder">Unavailable</span></strong>
                    </div>
                  </div>
                </div>
              </b-col>
               <b-col md="2" class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;"
                v-b-modal="'myModal'" 
            @click="handelShow(item)"> View</button>
            </b-col>
            </b-row>
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !productData.length }"></div>
      </div>

      <button v-if="!isLoading && productData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control paginationInput" :style="'width:' + ((pageNo.toString().length*10)) + 'px'" @input="pagechange" type="number"/>
        <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
        
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
    <!-- </div> -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      productDetailsShow: false,
      selectedValue: "",
      selectAllLabel: 'Select All',
      data: {},
      paginationStart:0,
      pageNo:1,
      paginationEnd:5,
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      productData: [],
      productData1: [],
      popoverShow: false,
      paginationLength: 0,
      popoverShow1: false,
      editItemId:'',
      mainProps: {
      width: 70,
      height: 70,
      class: 'm1',
    },
      isLoading:false,
      showItem:{},
      value: "1",
    };
  },
  mounted() {
    this.getProduct()
    this.$eventBus.$on("handleClickCreate", () => {
      this.productDetailsShow = !this.productDetailsShow;
      this.getProduct();
    }); 
  },
  computed:{
      search() {
          // let data = []
          let search = this.$store.state.searchData
          // data = this.productData1.filter(item => ((item.status == 1 ? 'Active' : 'Inactive').toLowerCase().includes(search) 
          //                                              || item.title.replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
          // this.productData = data
          this.getSearch(search);
        }, 
        app() {
          this.$store.state.searchData = ''
          this.getProduct()
        }
    },
  created() {},
  methods: {
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow3={}
      this.showItem = item
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/product-likecard/search`, { search: search, pageno: this.pageNo},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.productData = res.data.data
          this.paginationLength = res.data.totaldata
          this.productData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    HandleClickEdit(item){
    this.editItemId = item.id
    this.productDetailsShow = !this.productDetailsShow;
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/product-likecard/search`, { search: search, pageno: this.pageNo}).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.productData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.productData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/product-likecard/index`, { pageno: this.pageNo}).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.productData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.productData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
    },
    FilterStatus(status){
      let data
      if(status == 'All') data = this.productData1
      else if(status == 'Active') data = this.productData1.filter(item => (item.status))
      else if(status == 'Inactive') data = this.productData1.filter(item => (!item.status))
      this.productData = data
    },
    async getProduct(){
      if (this.$store.state.application) {
      this.isLoading = true
        this.$http.post(`${this.baseURL}/product-likecard/index`, { pageno: 1}).then((res) => {
            console.log(res.data.data,"res-=-=")
            this.productData=res.data.data
            this.paginationLength = res.data.totaldata
            this.productData1=res.data.data
            this.isLoading = false
          })
        }
        },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleClickCreate() {
      this.editItemId = null
      this.productDetailsShow = !this.productDetailsShow;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.danger-btn {
  width: fit-content;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}
.status-btn {
  width: fit-content;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select {
  max-width: none;
}
</style>

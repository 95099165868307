<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Ecommerce</h1>
    </div>

    <div>
      <b-row>
        <b-col md="3">
          <div class="card sideCard container">
            <ul>
              <!-- <li
              v-if="validTab.includes('Category')"
                :class="activeComponent == 'Category' ? 'active' : ''"
                @click="handleActiveComponent('Category')"
              > -->
              <li
                :class="activeComponent == 'Category' ? 'active' : ''"
                @click="handleActiveComponent('Category')"
              >
                <img
                  v-if="activeComponent == 'Category'"
                  src="../../../assets/images/svg/category-active.svg"
                />
                <img v-else src="../../../assets/images/svg/category.svg" />
                <span class="ml-2">Category</span>
              </li>
              <!-- <li
              v-if="validTab.includes('Product')"
                :class="activeComponent == 'Product' ? 'active' : ''"
                @click="handleActiveComponent('Product')"
              > -->
              <li
                :class="activeComponent == 'Product' ? 'active' : ''"
                @click="handleActiveComponent('Product')"
              >
                <img
                  v-if="activeComponent == 'Product'"
                  src="../../../assets/images/svg/product-active.svg"
                />
                <img v-else src="../../../assets/images/svg/product.svg" />
                <span class="ml-2">
                Product
                </span>
              </li>
              <li
                :class="activeComponent == 'Order' ? 'active' : ''"
                @click="handleActiveComponent('Order')"
              >
              <!-- <li
              v-if="validTab.includes('Order')"
                :class="activeComponent == 'Order' ? 'active' : ''"
                @click="handleActiveComponent('Order')"
              > -->
                <img
                  v-if="activeComponent == 'Order'"
                  src="../../../assets/images/svg/order-active.svg"
                />
                <img v-else src="../../../assets/images/svg/order.svg" />
                <span class="ml-2">
                Order
                </span>
              </li>
              <li
                :class="activeComponent == 'PromoCode' ? 'active' : ''"
                @click="handleActiveComponent('PromoCode')"
              >
              <!-- <li
              v-if="validTab.includes('PromoCode')"
                :class="activeComponent == 'PromoCode' ? 'active' : ''"
                @click="handleActiveComponent('PromoCode')"
              > -->
                <img
                  v-if="activeComponent == 'PromoCode'"
                  src="../../../assets/images/svg/offers-active.svg"
                />
                <img v-else src="../../../assets/images/svg/offers.svg" />
                <span class="ml-2"> Promo Code </span>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="9">
          <div class="container-fluid">
            <!-- <div v-if="activeComponent == 'Category' && validTab.includes('Category')"> -->
            <div v-if="activeComponent == 'Category'">
              <div v-if="subModule == 'SequenceManagement'">
                <SequenceManagement @handleClickBack="handleClickBack"/>
              </div>
              <div v-else-if="subModule == 'UpdateHomepageStatus'">
                <UpdateHomepageStatus  @handleClickBack="handleClickBack"/>
              </div>
              <div v-else-if="subModule == 'UpdateBackgroundColor'">
                <UpdateBackgroundColor  @handleClickBack="handleClickBack"/>
              </div>
              <div v-else>
                <Category @clickButton="clickButton"/>
              </div>
            </div>
            <!-- <div v-if="activeComponent == 'PromoCode' && validTab.includes('PromoCode')"> -->
            <div v-if="activeComponent == 'PromoCode'">
              <PromoCode />
            </div>
            <!-- <div v-if="activeComponent == 'Order' && validTab.includes('Order')"> -->
            <div v-if="activeComponent == 'Order'">
              <Order />
            </div>
            <!-- <div v-if="activeComponent == 'Product' && validTab.includes('Product')"> -->
            <div v-if="activeComponent == 'Product'">
              <Product />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import Category from "./Category.vue";
import PromoCode from "./PromoCode.vue";
import Order from "./Order.vue";
import Product from "./Product.vue";
import SequenceManagement from "./SequenceManagement.vue";
import UpdateHomepageStatus from "./UpdateHomepageStatus.vue";
import UpdateBackgroundColor from "./UpdateBackgroundColor.vue";
import { mapState } from 'vuex';
export default {
  components: {
    draggable,
    Category,
    PromoCode,
    Order,
    Product,
    SequenceManagement,
    UpdateHomepageStatus,
    UpdateBackgroundColor
  },
  data() {
    return {
      data: {},
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      activeComponent: "",
      validTab:[],
      subModule:''
    };
  },
  mounted(){
  },
   watch: {
    'sidebar': {
      handler: 'checkIsValid',
      immediate: true, 
    },
  '$store.state.application': {
    handler: 'checkIsValid',
    immediate: true,
  },
  },
  computed:{
      ...mapState(['sidebar']),
  },
  methods: { 
    clickButton(value){
      this.subModule = value
    },
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.filter(data =>data.name == 'Ecommerce');
        if(is_valid.length == 0){
          this.$router.push('/')
        }else{
          this.activeComponent = is_valid[0].rolePermission[0].permissionlist_id.name
        is_valid[0].rolePermission.map(item => {
          this.validTab.push(item.permissionlist_id.name)
        })
      console.log(this.validTab['Order'])
      this.$forceUpdate()
        }
      }
    },
    handleClickBack(){
      this.subModule = ''
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleActiveComponent(item) {
        this.subModule = ''
      this.activeComponent = item;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.container {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
}
.sideCard ul {
  list-style-type: none;
  margin-top: 2rem;
}
.sideCard ul li {
  padding: 1rem 2rem;
  cursor: pointer;
}
html {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.sideCard li.active {
  background: #f4f9fd;
  border-radius: 14px;
  color: #0a1629;
}
.sideCard .btn.btn-default:hover {
  box-shadow: 0 8px 25px -8px #82868b;
}
.sideCard li.active span{
  color: #3F8CFF;
}
</style>
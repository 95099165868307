<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h3 class="text-black">Promo Code{{ search }}</h3>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
          <div class="center custom-vs-select" style="border: none;width:5cm">
            <v-select :searchable="false" v-model="statusFilter" class="" :clearable="false" @input="getClientStore" shadow
              placeholder="Select" :options="['All', 'Active', 'Inactive']">
              <!-- <v-option class="d-none"
                >Status</v-option -->
              <!-- > -->
              <!-- <v-option label="All" value="all">All</v-option>
              <v-option label="Active" value="active">Active</v-option>
              <v-option label="Inactive" value="inactive">Inactive</v-option> -->
            </v-select>
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary @click="exportToCSV" title="Export CSV"
          variant="outline-primary" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>

        <button id="popover-button-variant" :style="popoverShow ? 'background-color: black;' : ''" href="#" tabindex="0"
          type="button" class="btn notofication-btn mr-1" @click="onPopovcerOpen()">
          <div>
            <img v-if="!popoverShow" id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />

            <img v-else id="task2" src="../../../assets/images/client/task-light.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-variant" triggers="click" class="custom-popover"
          :show.sync="popoverShow">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          <button @click="HandelClickMultipleDublicate()" type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button  @click="HandelClickMultipleEnableDisable()" type="button" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" @click="HandelClickMultipleDelete()" class="btn notofication-btn"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover>

        <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 16px"
          @click="addClientHandelClick()" v-b-modal.modal>
          +&nbsp; Add PromoCode
        </button>

        <b-modal ref="modal" id="modal" width="800px" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:70%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-6">
                <h2>Add Promo Code</h2>
              </div>
              <div class="form-group col-md-6">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" 
                  name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
                <!-- <input
                v-model="status"
                class="form-control"
                id="inputEmail4"
                placeholder="Select Status"
              /> -->
              </div>
            </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="col-md-7">
                  <b-form-group class="mb-2" label="Code">
                    <validation-provider #default="{ errors }" name="Code" rules="required">
                      <b-form-input v-model="code" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Code" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div> 
                
                <div class="col-md-12">
                  <b-form-group class="mb-2" label="Discount Amount" >
                    <validation-provider #default="{ errors }" name="Discount Amount" rules="required">
                      <b-form-radio-group
                      id="radio-group-1"
                      v-model="couponType"
                      @input="handelClickRadio()"
                      :options="radioOptions"
                      name="radio-options"
                    ></b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div v-if="couponType == 1" class="col-md-6">
                  <b-form-group class="mb-2" label="Discount Percentage">
                    <validation-provider #default="{ errors }" name="Discount Percentage" rules="required|integer">
                      <b-form-input v-model="discountPercentage" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Discount Percentage" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div v-if="couponType == 2" class="col-md-6">
                  <b-form-group class="mb-2" label="Discount Amount">
                    <validation-provider #default="{ errors }" name="Discount Amount" rules="required|integer">
                      <b-form-input v-model="discountAmount" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Discount Amount" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Start From">
                    <validation-provider #default="{ errors }" name="Start From" rules="required">
                      <flat-pickr class="form-control" v-model="startDate"  placeholder="Start From" style="background-color: transparent"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Expires At">
                    <validation-provider #default="{ errors }" name="Expires At" rules="required">
                      <flat-pickr class="form-control" v-model="expireDate"  placeholder="Expires At" style="background-color: transparent"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="modal-footer1">
                <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" data-dismiss="modal"
                  @click="closeModal">
                  Cancel
                </button>
                <button :disabled="invalid" type="button" @click="AddClient" class="btn btn-primary">{{ edit ? 'Update' :
                  'Add' }}</button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal id="showmodal" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding:20px 20px 0px 20px;" class="form-row">
              <div class="col-md-12">
                <h2>Promo Code </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Code</div>
                <div class="font-weight-bolder text-black">
                  <p class="font-weight-bolder mt-1">{{ showItem.code }}</p>
                </div>
              </div>
            </b-col>
            <b-col md="8">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder text-black">
                  <span v-if="showItem.is_active" class="btn btn-outline-success btn-sm">Active</span>
                  <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.sequence }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Start From</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.start_from }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Expires At</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.expires_at }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Type</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.type == 1 ? 'Discount Percentage' : 'Discount Number' }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Created At</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.created_at }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Updated At</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.modefied_at }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Discount Percentage</div>
                <div class="font-weight-bolder h4 text-black">
                  {{ showItem.discount_percentage }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Discount Number</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.discount_number }}</div>
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="promoCodeData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="promoCodeData" handle=".draggable-task-handle" @change="draggableChange($event)" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in promoCodeData"
          :key="index" @dblclick="handelShow(item)">
          <b-row class="p-1">
            <b-col md="2">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox v-if="popoverShow" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl"
                    inline />
                </div>
              <div class="ml-1">
                <div class="mb-half">Code</div>
                <div class="font-weight-bolder text-black">{{ item.code }}</div>
              </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Discount Percentage</div>
                <div class="font-weight-bolder text-black">{{ item.discount_percentage }}</div>
              </div>
            </b-col>
            <!-- <b-col md="2" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Discouont Number</div>
                <div class="font-weight-bolder text-black">{{ item.discount_number }}</div>
              </div>
            </b-col> -->
            <b-col md="2" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder text-black">{{ item.sequence }}</div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Start From</div>
                <div class="font-weight-bolder text-black">{{ item.start_from }}</div>
              </div>
            </b-col>
            <!-- <b-col md="2" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Expire At</div>
                <div class="font-weight-bolder text-black">{{ item.expires_at }}</div>
              </div>
            </b-col> -->
            <b-col md="1" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder">
                  <div :class="item.is_active == 1 ? 'success-btn' : 'danger-btn'">
                    <strong><span class="boder">{{ item.is_active == 1 ? 'Active' : 'Inactive' }}</span></strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="1" class="
                d-flex
                align-items-center
                justify-content-center
                text-black
              ">
              <div :id="'popover-button-variant' + item.id" href="#"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
          <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <!-- <button @click="onPopovcerOpen3(item.id)" class="notofication-btn" style="color: #408dff; margin: 5px"
              size="sm">
              Cancel
            </button> -->

            <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>

            <!-- <button
            type="button"
            class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px"
          >
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button> -->
            <button type="button" class="btn notofication-btn ml-1" @click="handelEdit(item.id)" v-b-modal.modal
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square"
                viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !promoCodeData.length }"></div>
    </div>

    <button v-if="!isLoading && promoCodeData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control paginationInput"
          :style="'width:' + ((pageNo.toString().length * 10)) + 'px'" @input="pagechange" type="number" />
        <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import draggable from "vuedraggable";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import vSelect from 'vue-select';
import moment from 'moment';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    draggable,
    vSelect,
    quillEditor, BImg,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      data: {},
      required, integer,
      isLoading: false,
      filter: 'All',
        radioOptions: [
          { text: 'Discount Percentage', value: '1' },
          { text: 'Discount Amount', value: '2' },
        ],
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      categoryOptions: [],
      promoCodeData: [],
      promoCodeData1: [],
      popoverShow: false,
      categoriesViewMore:{},
      edit: false,
      selectAllLabel: 'Select All',
      paginationStart: 0,
      pageNo: 1,
      paginationEnd: 10,
      code: '',
      couponType: '1',
      discountAmount: 0,
      discountPercentage:0,
      startDate:'',
      expireDate:'',
      sequence:0,
      status:'',
      checkBoxIds: {},
      selectAll: false,
      statusFilter: "All",
      paginationLength: 0,
      showItem: {},
    };
  },
  created() {
  },
  mounted() {
    this.getClientStore();
    this.$http.post(`${this.baseURL}/client-categories/index`).then((res) => {
      console.log(res.data.data, "res-=-=")
      this.categoryOptions = res.data.data
      this.checkBoxIds = {}
    })
  },
  computed: {
    search() {
      // let data = []
      let search = this.$store.state.searchData.replace(/\s/g, '').toLowerCase()
      // data = this.promoCodeData1.filter(item => (item.name_en.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.website.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.sequence.toString().replace(/\s/g,'').toLowerCase().includes(search)
      //                                              || (item.categories[0] ? item.categories[0].name : '').replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
      //                                              ))
      this.getSearch(search);
      // this.$store.state.searchData; 
    },
  },
  methods: {
    categoriesViewMoreHandelClick(id){
    this.categoriesViewMore[id] = !this.categoriesViewMore[id];
    this.$forceUpdate();
    },
    convertToPlainText(quillContent) {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(quillContent, 'text/html');
      // this.plainTextContent = parsedHtml.body.textContent;
      return parsedHtml.body.textContent
    },
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/ecommercecoupons/search`, { statusFilter: this.statusFilter, search: search, pageno: this.pageNo },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.promoCodeData = res.data.data
          this.paginationLength = res.data.totaldata
          this.promoCodeData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      const csvData = json2csv.parse(this.promoCodeData)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'PromoCode.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    handelChangeFilter() {
      console.log(this.filter)
      if (this.filter == "All") {
        this.promoCodeData = this.promoCodeData1
      }
      else if (this.filter == "Active") {
        let data = []
        data = this.promoCodeData1.filter(item => item.is_active == 1)
        this.promoCodeData = data
      }
      else if (this.filter == "Inactive") {
        let data = []
        data = this.promoCodeData1.filter(item => item.is_active == 0)
        this.promoCodeData = data
      }
    },
    handelClickRadio(){
      this.discountPercentage = 0
      this.discountAmount = 0
    },
    addClientHandelClick() {
      this.edit = false
      this.editItem = ''
      this.code = ''
      this.couponType = '1'
      this.discountAmount = 0
      this.discountPercentage = 0
      this.startDate =''
      this.expireDate =''
      this.status =''
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/ecommercecoupons/get-sequence`).then((json) => {
        console.log(json.data.sequence, "data-=-=--")
        this.sequence = json.data.sequence
      })
    },
    handelEdit(id) {
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/ecommercecoupons/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.code = this.editItem.code
          this.couponType = this.editItem.type
          this.discountAmount = this.editItem.discount_number
          this.sequence = this.editItem.sequence
          this.discountPercentage = this.editItem.discount_percentage
          this.startDate = this.editItem.start_from
          this.expireDate = this.editItem.expires_at
          this.status = this.editItem.is_active ? true : false,
          console.log(this.editItem)
          this.edit = true
        })
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/ecommercecoupons/search`, { statusFilter: this.statusFilter, search: search, pageno: this.pageNo }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.promoCodeData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.promoCodeData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/ecommercecoupons/index`, { statusFilter: this.statusFilter, pageno: this.pageNo }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.promoCodeData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.promoCodeData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    categoryChange() {
      this.category_ids = []
      this.category.map(item => {
        this.category_ids.push(item.id)
      })
    },
    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/ecommercecoupons/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    draggableChange(e) {
      console.log(e.moved, "e0-0=-=")
      let search = this.$store.state.searchData
      console.log(this.promoCodeData1[e.moved.newIndex].sequence, this.promoCodeData1[e.moved.oldIndex].sequence, "e0-0=-=")
      console.log(e.moved.newIndex, e.moved.oldIndex, "e0-0=-=")
      let data = {
        newIndex: this.promoCodeData1[e.moved.newIndex].sequence,
        oldIndex: this.promoCodeData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        statusFilter: this.statusFilter
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/ecommercecoupons/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.promoCodeData = []
          this.promoCodeData1 = []
          this.promoCodeData = res.data.data
          this.paginationLength = res.data.totaldata
          this.promoCodeData1 = res.data.data
        })
    },
    HandelClickMultipleEnableDisable(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/ecommercecoupons/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.checkBoxIds = {}
            this.popoverShow= false
            this.getClientStore()
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDublicate(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/ecommercecoupons/copy`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Success",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Success",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.checkBoxIds = {}
            this.popoverShow= false
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/ecommercecoupons/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.checkBoxIds = {}
          this.$forceUpdate()
        })
    },
    AddClient() {
      console.log(this.startDate,this.expireDate)
      let data = {
        code : this.code,
        type : this.couponType,
        sequence: this.sequence,
        discount_number : this.discountAmount,
        discount_percentage : this.discountPercentage,
        start_from : this.startDate,
        expires_at : this.expireDate,
        is_active : this.status ? 1 : 0
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      console.log(data)
      this.$refs.submitPrevent.validate().then((success) => {
        console.log(success)
        this.$http.post(`${this.baseURL}/ecommercecoupons/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 'error') {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.getClientStore()
              this.checkBoxIds = {}
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
      })
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      console.log(data)
      if (data.length == this.promoCodeData.length) {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.promoCodeData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    // CheckBoxHandelClick(id) {
    //   console.log(this.checkBoxIds[id])
    //   this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
    //   this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
    //   console.log(this.checkBoxIds[id])

    //   this.$forceUpdate()
    // },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    async getClientStore() {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/ecommercecoupons/index`, { statusFilter: this.statusFilter, pageno: this.pageNo }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.promoCodeData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.promoCodeData1 = res.data.data
        this.isLoading = false
      })

      // let data = await getpromoCodeData();
      // console.log(data,"data=====");
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.custom-radio {
  border: none;
  border-radius: 10px;
  color: #000 !important;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  min-width: 250px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}

.modal-body {
  padding: 0px 40px 40px 40px !important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  padding:0;
}

textarea.form-control {
  min-height: 150px !important;
}

.popover1-body {
  padding: 0;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>

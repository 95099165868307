<template>
  <div>
    <validation-observer ref="submitPrevent">
    <b-row>
      <b-col md="6">
        <b-form-group class="mb-2" label="User ID">
          <validation-provider #default="{ errors }" name="User ID" rules="required">
            <b-form-input v-model="user_id" :state="errors.length > 0 ? false : null" placeholder="User ID" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="mb-2" label="Order Status">
          <validation-provider #default="{ errors }" name="Order Status" rules="required">
            <v-select v-model="order_status" :options="[
              {name:'Pending',id:0},
              {name:'Success',id:1},
              {name:'Failed',id:2},
            ]" label="name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="mb-2" label="Payment ID">
          <validation-provider #default="{ errors }" name="Payment ID" rules="required">
            <b-form-input v-model="payment_id" :state="errors.length > 0 ? false : null" placeholder="Payment ID" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product,index) in product_list" :key="index">
              <td>
                <v-select v-model="item.product" :options="productionOptions" label="name_en" />
              </td>
              <td>
                <b-form-input v-model="item.quantity" placeholder="Quantity" />
              </td>
              <td>

              </td>
            </tr>
          </tbody>
        </table>

      </b-col>

    </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { 
  BRow,
  BCol,
  BFormGroup,
  BFormInput
 } from 'bootstrap-vue';
import { ValidationObserver , ValidationProvider } from 'vee-validate';
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput
  },
  data() {
    return {
      user_id:'',
      product_list:[
        {
          product:'',
          quantity:''
        }
      ],
      payment_id:'',
      order_status:'',
      productionOptions:[]
    };
  },
  methods: {
  },
};
</script>

